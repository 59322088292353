<template>
    <v-card elevation="2" tile width="100%" class="pa-0 pa-4">
        <v-row class="ma-0 pa-0 staff-details" style="height: calc(100vh - 200px)">
            <v-col cols="4" class="pa-0">
                <v-skeleton-loader height="100%" type="image" v-if="detailLoading"/>
                 <v-tooltip top v-if="staffPageSettingId && $helpers.getCurData('curUserPermissions').indexOf('call_centre_coordinator') !== -1">
                    <template v-slot:activator="{on, attrs}">
                        <a :href="`/app-settings/edit-setting/${staffPageSettingId}`" v-bind="attrs" v-on="on"
                            class="text-decoration-none float-right" target="_blank">
                                <v-icon color="secondary" size="30">mdi-pencil-box-outline</v-icon>
                        </a>
                    </template>
                    <span>Staff Page Team Editor</span>
                </v-tooltip>
                <div v-if="!detailLoading" class="tip-tap-content" v-html="model.STAFF_PAGE_TEAM"></div>
            </v-col>
            <v-col cols="8" class="py-0 pr-0">
                <v-skeleton-loader height="100%" type="image" v-if="loading"/>
                <div id="map" v-else class="map-preview"></div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            model: {
                STAFF_PAGE_TEAM: null,
                STAFF_MAP_CO_ORD: null
            },
            locations: [],
            detailLoading: false,
            staffPageSettingId:  null,
            loading: true
        }
    },
    created() {
        this.getMapWithTeam();
    },
    mounted() {
        this.getDonorMarkers();
    },
    methods: {
        ...mapActions(['getOptionsData', 'pageNavigation', 'getCampaignMarkers']),

        mapUpdate() {
            if (this.model.STAFF_MAP_CO_ORD) {
              const staffMarker = {latitude: null, longitude: null};
              [staffMarker.latitude, staffMarker.longitude] = this.model.STAFF_MAP_CO_ORD.split(',');
              this.locations.push(staffMarker);
            }

            const map = new google.maps.Map(document.getElementById('map'), {
                zoom: 9,
                center:  { lat: -37.817780, lng: 145.191650 },
                // mapTypeId: "hybrid"
            });

            const icon = {
                url: "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi3_hdpi.png", // url
                scaledSize: new google.maps.Size(20, 28), // scaled size
                origin: new google.maps.Point(0,0), // origin
                anchor: new google.maps.Point(0, 0) // anchor
            };

            const markers = this.locations.map((position, i) => {
                const marker = new google.maps.Marker({
                  position: new google.maps.LatLng(position['latitude'], position['longitude']),
                  icon: icon
                });

                // markers can only be keyboard focusable when they have click listeners
                // open info window when marker is clicked
                // marker.addListener("click", () => {
                //   infoWindow.setContent(label);
                //   infoWindow.open(map, marker);
                // });

                return marker;
            });

            // Add a marker clusterer to manage the markers.
            new markerClusterer.MarkerClusterer({ markers, map });
        },
        async getMapWithTeam() {
            this.detailLoading = true;
            const reqData = {
                name: 'search',
                value: {
                    option_name: ['STAFF_PAGE_TEAM', 'STAFF_MAP_CO_ORD'],
                }
            }
            await this.getOptionsData(reqData).then((response) => {
                const resp = response.data.data || null;
                if (resp) {
                    this.model = resp;
                }
                this.detailLoading = false;
            }).catch(err => this.detailLoading = false);

            await this.pageNavigation(`${window.VUE_APP_MS_URL}donate/options?page=1&q=STAFF_PAGE_TEAM`)
                .then(resp => this.staffPageSettingId = resp.data.length === 1 ? resp.data[0].id : null);
        },
        async getDonorMarkers() {
          const self = this;
          await this.getCampaignMarkers('').then(resp => {
            self.locations = self.locations.concat(resp);
            self.loading = false;
            setTimeout(() => {
                this.mapUpdate();
            });
          }).catch(err => {
            self.locations = [];
            self.loading = false;
          });
        }
    }
}
</script>
